import { CONSTANTS } from 'src/redux';
import { TYPES as editUsersActions } from './editUsersActions';

const initialState = {
  user: {
    _id: '',
    name: '',
    email: ''
  },
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case editUsersActions.GET_USER:
      return {
        ...state,
        type: action.type,
        status: action.status,
        user: (CONSTANTS.STATUS.DONE === action.status ? action.payload : initialState.user)
      };
    case editUsersActions.UPDATE_USER:
      return {
        ...state,
        type: action.type,
        status: action.status
      };
    case editUsersActions.UPDATE_USER_REDIRECT:
      return {
        ...state,
        type: action.type,
        status: action.status,
        user: initialState.user
      };
    default:
      return state;
  }
};

export default reducers;
