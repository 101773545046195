import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Grid,
  makeStyles,
  LinearProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import {
  Users as UsersIcon,
  Book as BookIcon,
  Film as VideosIcon,
  List as MenuIcon
} from 'react-feather';
import { actions, CONSTANTS } from 'src/redux';
import Status from './Status';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { listTotals } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(actions.dashboard.listTotals.list(1));
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        {listTotals.status === CONSTANTS.STATUS.DONE ? (
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Status
                title="Usuários Biblioteca"
                total={listTotals.totalUsers}
                icon={<UsersIcon />}
              />
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Status
                title="E-books"
                total={listTotals.totalEbooks}
                icon={<BookIcon />}
              />
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Status
                title="Vídeos"
                total={listTotals.totalVideos}
                icon={<VideosIcon />}
              />
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Status
                title="Menu"
                total={listTotals.totalMenus}
                icon={<MenuIcon />}
              />
            </Grid>
          </Grid>
        ) : (
          <LinearProgress />
        )}
      </Container>
    </Page>
  );
};

export default Dashboard;
