import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
  LinearProgress
} from '@material-ui/core';

import { actions, CONSTANTS } from 'src/redux';
import Page from 'src/components/Page';
import ProfileDetails from './ProfileDetails';

const marcas = ['anhanguera', 'unime', 'uniasselvi',
  'unirondon', 'unopar', 'fama', 'unic',
  'uniderp', 'pitagoras', 'lfg', 'uk'];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const MenuCreateView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createMenu } = useSelector((state) => state.menu);

  useEffect(() => {
    if (
      createMenu.status === CONSTANTS.STATUS.DONE
      && createMenu.type === 'MENU::CREATE_MENU'
    ) {
      dispatch(actions.menu.createMenu.redirect());
      navigate('/app/menu');
    }
  }, [createMenu]);

  return (
    <Page className={classes.root} title="MenuCreateView">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            {createMenu.status === CONSTANTS.STATUS.DONE || createMenu.status === null ? (
              <ProfileDetails marcas={marcas} />
            ) : (
              <LinearProgress />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default MenuCreateView;
