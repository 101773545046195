import { CONSTANTS } from 'src/redux';
import { TYPES as userActions } from './userActions';

const initialState = {
  name: '',
  email: '',
  token: '',
  isLoggedIn: false,
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case userActions.LOGIN:
      return {
        ...state,
        type: action.type,
        status: action.status,
        ...(CONSTANTS.STATUS.DONE === action.status ? action.payload : {})
      };
    case userActions.LOGOUT:
    case userActions.CLOSE_ALERT:
      return {
        ...initialState,
        type: action.type,
        status: action.status
      };
    default:
      return state;
  }
};

export default reducers;
