import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  GET_USER: 'USERS::GET_USER',
  UPDATE_USER: 'USERS::UPDATE_USER',
  UPDATE_USER_REDIRECT: 'USERS::UPDATE_USER_REDIRECT',
};

const get = (id) => async (dispatch) => {
  dispatch({
    type: TYPES.GET_USER,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    const response = await client.get(`user/${id}`);
    dispatch({
      type: TYPES.GET_USER,
      status: CONSTANTS.STATUS.DONE,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.GET_USER,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const update = (id, user) => async (dispatch) => {
  dispatch({
    type: TYPES.UPDATE_USER,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    await client.put(`user/${id}`, user);
    dispatch({
      type: TYPES.UPDATE_USER,
      status: CONSTANTS.STATUS.DONE
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.UPDATE_USER,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const redirect = () => async (dispatch) => {
  dispatch({
    type: TYPES.UPDATE_USER_REDIRECT,
    status: CONSTANTS.STATUS.DONE
  });
};

const actions = {
  get,
  update,
  redirect
};

export default actions;
