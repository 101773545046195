import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';
import axios from 'axios';

export const TYPES = {
  UPLOAD_EBOOK: 'EBOOK::UPLOAD_EBOOK',
  UPLOAD_EBOOK_RESET: 'EBOOK::UPLOAD_EBOOK_RESET'
};

const reset = () => async (dispatch) => {
  dispatch({
    type: TYPES.UPLOAD_EBOOK_RESET,
    status: CONSTANTS.STATUS.DONE
  });
};

const getSignedUrl = async (filename) => {
  try {
    const body = { filename };
    const client = await httpClient();
    const response = await client.post('signedUrl/ebook', body);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const upload = (file) => async (dispatch) => {
  dispatch({
    type: TYPES.UPLOAD_EBOOK,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const signedUrl = await getSignedUrl(file.name);
    await axios.put(
      signedUrl.uploadUrl,
      file,
      { headers: { 'Content-type': file.type } }
    );
    dispatch({
      type: TYPES.UPLOAD_EBOOK,
      status: CONSTANTS.STATUS.DONE,
      payload: {
        currentFilename: signedUrl.currentFilename,
        downloadUrl: signedUrl.downloadUrl
      }
    });
    dispatch(reset());
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.UPLOAD_EBOOK,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const actions = {
  upload
};

export default actions;
