import React, { useState, useEffect } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  FormControlLabel,
  Switch,
  LinearProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ClearIcon from '@material-ui/icons/Clear';

import { actions, CONSTANTS } from 'src/redux';

const useStyles = makeStyles(() => ({
  root: {}
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const typePublication = [
  { id: 'apostila', name: 'Apostila' },
  { id: 'artigo', name: 'Artigo' },
  { id: 'dicionario', name: 'Dicionário' },
  { id: 'dissertacao', name: 'Dissertação' },
  { id: 'ebook', name: 'E-book' },
  { id: 'ebookinstitucional', name: 'E-book - Institucional' },
  { id: 'multidisciplinar', name: 'Multidisciplinar' },
  { id: 'norma', name: 'Norma' },
  { id: 'obrarara', name: 'Obra-Rara' },
  { id: 'obrasliterarias', name: 'Obras Literárias' },
  { id: 'periodico', name: 'Periodico' },
  { id: 'regulamento', name: 'Regulamento' },
  { id: 'tcc', name: 'TCC' },
  { id: 'tccinstitucional', name: 'TCC - Institucional' },
  { id: 'tutorial', name: 'Tutorial' }
];

const brands = [
  { id: 'anhanguera', name: 'Anhanguera' },
  { id: 'unime', name: 'Unime' },
  { id: 'uniasselvi', name: 'Uniasselvi' },
  { id: 'unirondon', name: 'Unirondon' },
  { id: 'unopar', name: 'Unopar' },
  { id: 'fama', name: 'Fama' },
  { id: 'unic', name: 'Unic' },
  { id: 'uniderp', name: 'Uniderp' },
  { id: 'pitagoras', name: 'Pitágoras' },
  { id: 'lfg', name: 'LFG' },
  { id: 'uk', name: 'UK' }
];

const EbookDetails = ({ className, ebook }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uploadThumbnail } = useSelector((state) => state.ebook);
  const { uploadEbook } = useSelector((state) => state.ebook);
  const [thumbnail, setThumbnail] = useState(null);
  const [ebookFile, setEbookFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState({
    thumbnail: ebook.files.find((file) => file.type === 'thumbnail'),
    ebook: ebook.files.find((file) => file.type === 'ebook')
  });
  const [inputs, setInputs] = useState({
    title: ebook.title,
    authors: ebook.authors,
    pages: ebook.pages,
    isbn_issn: ebook.isbn_issn,
    description: ebook.description,
    keyWord: ebook.keyWord,
    publishing: ebook.publishing,
    subject: ebook.subject,
    bibliographicReferences: ebook.bibliographicReferences,
    year: ebook.year,
    link: ebook.link,
    typePublication: ebook.typePublication,
    highlight: Boolean(ebook.highlight),
    restrictedBrand: Boolean(ebook.restrictedBrand),
    brand: ebook.brand,
    exclusive: Boolean(ebook.exclusive),
    avaible: Boolean(ebook.avaible),
    publicationAuthorizationTCC: ebook.publicationAuthorizationTCC,
    publicationAuthorization: ebook.publicationAuthorization,
    supervisor: ebook.supervisor,
    image: ebook.image,
    files: !ebook.files ? [] : ebook.files
  });

  const handleInputChange = (event) => {
    setInputs((prevInputs) => ({ ...prevInputs, [event.target.name]: event.target.value }));
  };

  const handleBrand = (event, updatedBrands) => {
    setInputs((prevInputs) => ({ ...prevInputs, brand: updatedBrands }));
  };

  const handleSwitch = (event) => {
    setInputs((prevInputs) => ({ ...prevInputs, [event.target.name]: event.target.checked }));
  };

  const handleSubmit = () => {
    let files = [];
    if (uploadedFiles.thumbnail) files = [...files, uploadedFiles.thumbnail];
    if (uploadedFiles.ebook) files = [...files, uploadedFiles.ebook];
    const updateEbook = {
      ...inputs,
      files
    };
    dispatch(actions.ebook.editEbook.update(ebook._id, updateEbook));
  };

  useEffect(() => {
    if (
      uploadThumbnail.type === 'EBOOK::UPLOAD_THUMBNAIL'
      && uploadThumbnail.status === CONSTANTS.STATUS.DONE
    ) {
      setUploadedFiles({
        ...uploadedFiles,
        thumbnail: {
          name: uploadThumbnail.currentFilename,
          type: 'thumbnail'
        }
      });
      setInputs((prevInputs) => ({ ...prevInputs, image: uploadThumbnail.downloadUrl }));
    }
  }, [uploadThumbnail]);

  useEffect(() => {
    if (
      uploadEbook.type === 'EBOOK::UPLOAD_EBOOK'
      && uploadEbook.status === CONSTANTS.STATUS.DONE
    ) {
      setUploadedFiles({
        ...uploadedFiles,
        ebook: {
          name: uploadEbook.currentFilename,
          type: 'ebook'
        }
      });
      setInputs((prevInputs) => ({ ...prevInputs, link: uploadEbook.downloadUrl }));
    }
  }, [uploadEbook]);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader title="Editar e-book" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Código de cadastro"
                name="id"
                required
                value={ebook._id}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Sharepoint ID"
                name="id"
                required
                value={ebook.sharepointId}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Título"
                name="title"
                onChange={handleInputChange}
                required
                value={inputs.title}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Autores"
                name="authors"
                onChange={handleInputChange}
                required
                value={inputs.authors}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Páginas"
                name="pages"
                onChange={handleInputChange}
                required
                value={inputs.pages}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="ISBN/ISSN"
                name="isbn_issn"
                onChange={handleInputChange}
                required
                value={inputs.isbn_issn}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Descrição"
                name="description"
                onChange={handleInputChange}
                required
                value={inputs.description}
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Palavras-chave"
                name="keyWord"
                onChange={handleInputChange}
                required
                value={inputs.keyWord}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Editora"
                name="publishing"
                onChange={handleInputChange}
                required
                value={inputs.publishing}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Assunto"
                name="subject"
                onChange={handleInputChange}
                required
                value={inputs.subject}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Ano"
                name="year"
                onChange={handleInputChange}
                required
                value={inputs.year}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Referências Bibliográficas"
                name="bibliographicReferences"
                onChange={handleInputChange}
                required
                value={inputs.bibliographicReferences}
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Link da imagem"
                name="image"
                onChange={handleInputChange}
                required
                value={inputs.image}
                variant="outlined"
                disabled={!!uploadedFiles.thumbnail}
              />
              {uploadThumbnail.status !== CONSTANTS.STATUS.FETCHING
                ? (
                  <>
                    {!uploadedFiles.thumbnail && (
                      <>
                        <div style={{ margin: '6px 0' }}>
                          <DropzoneArea
                            dropzoneText="Clique, ou arraste e solte o arquivo para selecionar"
                            filesLimit={1}
                            showPreviews={false}
                            showPreviewsInDropzone
                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                            onChange={(files) => setThumbnail(files[0])}
                          />
                        </div>
                        <span style={{ marginRight: '6px' }}>
                          <Button
                            color="primary"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            onClick={() => {
                              if (!thumbnail) return;
                              dispatch(actions.ebook.uploadThumbnail.upload(thumbnail));
                            }}
                            disabled={!thumbnail}
                          >
                            Enviar
                          </Button>
                        </span>
                      </>
                    )}
                    {uploadedFiles.thumbnail && (
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<ClearIcon />}
                        onClick={() => {
                          setUploadedFiles({ ...uploadedFiles, thumbnail: null });
                          setInputs((prevInputs) => ({ ...prevInputs, image: '' }));
                        }}
                        disabled={!uploadedFiles.thumbnail}
                      >
                        Alterar link
                      </Button>
                    )}
                  </>
                ) : <LinearProgress /> }
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Link do e-book"
                name="link"
                onChange={handleInputChange}
                required
                value={inputs.link}
                variant="outlined"
                disabled={!!uploadedFiles.ebook}
              />
              {uploadEbook.status !== CONSTANTS.STATUS.FETCHING
                ? (
                  <>
                    {!uploadedFiles.ebook && (
                      <>
                        <div style={{ margin: '6px 0' }}>
                          <DropzoneArea
                            dropzoneText="Clique, ou arraste e solte o arquivo para selecionar"
                            filesLimit={1}
                            showPreviews={false}
                            showPreviewsInDropzone
                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                            maxFileSize={209715200}
                            onChange={(files) => setEbookFile(files[0])}
                          />
                        </div>
                        <span style={{ marginRight: '6px' }}>
                          <Button
                            color="primary"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            onClick={() => {
                              if (!ebookFile) return;
                              dispatch(actions.ebook.uploadEbook.upload(ebookFile));
                            }}
                            disabled={!ebookFile}
                          >
                            Enviar
                          </Button>
                        </span>
                      </>
                    )}
                    {uploadedFiles.ebook && (
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<ClearIcon />}
                        onClick={() => {
                          setUploadedFiles({ ...uploadedFiles, ebook: null });
                          setInputs((prevInputs) => ({ ...prevInputs, link: '' }));
                        }}
                        disabled={!uploadedFiles.ebook}
                      >
                        Alterar link
                      </Button>
                    )}
                  </>
                ) : <LinearProgress /> }
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="typePublication">Tipo de publicação</InputLabel>
                <Select
                  native
                  value={inputs.typePublication}
                  onChange={handleInputChange}
                  label="Tipo de publicação"
                  inputProps={{
                    name: 'typePublication',
                    id: 'typePublication',
                  }}
                >
                  <option aria-label="none" value="" />
                  {typePublication.map((type) => (
                    <option key={type.id} value={type.id}>{type.name}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <Autocomplete
                multiple
                id="brand"
                options={brands}
                value={brands.filter((brand) => {
                  return inputs.brand.some((selectedBrand) => selectedBrand.id === brand.id);
                })}
                disableCloseOnSelect
                onChange={handleBrand}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="brand"
                    label="Marcas"
                    placeholder="Marcas"
                  />
                )}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={inputs.highlight}
                    onChange={handleSwitch}
                    name="highlight"
                  />
                )}
                label="Destaque"
              />
              <FormControlLabel
                control={(
                  <Switch
                    checked={inputs.restrictedBrand}
                    onChange={handleSwitch}
                    name="restrictedBrand"
                  />
                )}
                label="Restrito por marca"
              />
              <FormControlLabel
                control={(
                  <Switch
                    checked={inputs.exclusive}
                    onChange={handleSwitch}
                    name="exclusive"
                  />
                )}
                label="Exclusivo"
              />
              <FormControlLabel
                control={(
                  <Switch
                    checked={inputs.avaible}
                    onChange={handleSwitch}
                    name="avaible"
                  />
                )}
                label="Disponível"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Autorização para publicação (TCC)"
                name="publicationAuthorizationTCC"
                onChange={handleInputChange}
                required
                value={inputs.publicationAuthorizationTCC}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Autorização para publicação"
                name="publicationAuthorization"
                onChange={handleInputChange}
                required
                value={inputs.publicationAuthorization}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Orientador"
                name="supervisor"
                onChange={handleInputChange}
                required
                value={inputs.supervisor}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Atualizar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

EbookDetails.propTypes = {
  className: PropTypes.string,
  ebook: PropTypes.object
};

export default EbookDetails;
