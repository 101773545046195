import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import listUsersActions from 'src/redux/users/listUsers/listUsersActions';
import listUsersReducers from 'src/redux/users/listUsers/listUsersReducers';
import editUsersActions from 'src/redux/users/editUsers/editUsersActions';
import editUsersReducers from 'src/redux/users/editUsers/editUsersReducers';
import createUsersActions from 'src/redux/users/createUsers/createUsersActions';
import createUsersReducers from 'src/redux/users/createUsers/createUsersReducers';
import deleteUsersActions from 'src/redux/users/deleteUsers/deleteUsersActions';
import deleteUsersReducers from 'src/redux/users/deleteUsers/deleteUsersReducers';

const usersCombineReducers = combineReducers({
  listUsers: listUsersReducers,
  editUsers: editUsersReducers,
  createUsers: createUsersReducers,
  deleteUsers: deleteUsersReducers
});

const usersAction = {
  listUsers: listUsersActions,
  editUsers: editUsersActions,
  createUsers: createUsersActions,
  deleteUsers: deleteUsersActions
};

const usersReducer = persistReducer(
  {
    key: 'users',
    storage,
    whitelist: [],
    blacklist: []
  },
  usersCombineReducers
);

export { usersReducer, usersAction };
