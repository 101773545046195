import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';

import LoginView from 'src/views/auth/LoginView';

// pages admin
import CmsUsersListView from 'src/views/cms/UsersListView';
import CmsUsersCreateView from 'src/views/cms/UsersCreateView';
import CmsUsersEditView from 'src/views/cms/UsersEditView';

// pages users
import UsersListView from 'src/views/users/UsersListView';
import UsersCreateView from 'src/views/users/UsersCreateView';
import UsersEditView from 'src/views/users/UsersEditView';

// pages menu
import MenuListView from 'src/views/menu/MenuListView';
import MenuCreateView from 'src/views/menu/MenuCreateView';
import MenuEditView from 'src/views/menu/MenuEditView';

// pages users
import EbookListView from 'src/views/ebook/EbookListView';
import EbookCreateView from 'src/views/ebook/EbookCreateView';
import EbookEditView from 'src/views/ebook/EbookEditView';

// pages video
import VideoListView from 'src/views/video/VideoListView';
import VideoCreateView from 'src/views/video/VideoCreateView';
import VideoEditView from 'src/views/video/VideoEditView';

const routes = (isLoggedIn) => [
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'cms/users', element: <CmsUsersListView /> },
      { path: 'cms/users/create', element: <CmsUsersCreateView /> },
      { path: 'cms/users/edit/:id', element: <CmsUsersEditView /> },
      { path: 'menu', element: <MenuListView /> },
      { path: 'menu/create', element: <MenuCreateView /> },
      { path: 'menu/edit/:id', element: <MenuEditView /> },
      { path: 'users', element: <UsersListView /> },
      { path: 'users/create', element: <UsersCreateView /> },
      { path: 'users/edit/:id', element: <UsersEditView /> },
      { path: 'ebooks', element: <EbookListView /> },
      { path: 'ebooks/create', element: <EbookCreateView /> },
      { path: 'ebooks/edit/:id', element: <EbookEditView /> },
      { path: 'videos', element: <VideoListView /> },
      { path: 'videos/create', element: <VideoCreateView /> },
      { path: 'videos/edit/:id', element: <VideoEditView /> },
      { path: 'account', element: <AccountView /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'test', element: <SettingsView /> },
      { path: '*', element: <NotFoundView /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: 'login',
        element: isLoggedIn ? <Navigate to="/app/dashboard" /> : <LoginView />
      },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
