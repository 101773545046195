import { TYPES as deleteEbookActions } from './deleteEbookActions';

const initialState = {
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case deleteEbookActions.DELETE_EBOOK:
      return {
        ...state,
        type: action.type,
        status: action.status
      };
    default:
      return state;
  }
};

export default reducers;
