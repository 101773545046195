import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

import { actions } from 'src/redux';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const handlePassword = (event) => setPassword(event.target.value);
  const handlePasswordConfirm = (event) => setPasswordConfirm(event.target.value);

  const handleSubmit = () => {
    const updateUser = {
      name: user.name,
      email: user.email,
      password
    };
    dispatch(actions.admin.editUsers.update(user._id, updateUser));
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader subheader="É possível atualizar somente a senha" title="Editar usuário administrativo" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Nome"
                name="name"
                required
                value={user.name}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                required
                value={user.email}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Nova Senha"
                name="password"
                type="password"
                onChange={handlePassword}
                required
                value={password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Confirme nova senha"
                name="password-confirm"
                type="password"
                onChange={handlePasswordConfirm}
                required
                value={passwordConfirm}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Atualizar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default ProfileDetails;
