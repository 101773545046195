import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  LOGIN: 'USER::LOGIN',
  LOGOUT: 'USER::LOGOUT',
  CLOSE_ALERT: 'USER::CLOSE_ALERT'
};

const login = (email, password) => async (dispatch) => {
  dispatch({
    type: TYPES.LOGIN,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    const response = await client.post('admin/login', { email, password });
    dispatch({
      type: TYPES.LOGIN,
      status: CONSTANTS.STATUS.DONE,
      payload: {
        ...response.data,
        isLoggedIn: true
      }
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.LOGIN,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const logout = () => async (dispatch) => {
  dispatch({
    type: TYPES.LOGOUT,
    status: CONSTANTS.STATUS.DONE
  });
};

const closeAlert = () => (dispatch) => {
  dispatch({
    type: TYPES.CLOSE_ALERT,
    status: CONSTANTS.STATUS.DONE
  });
};

const actions = {
  login,
  logout,
  closeAlert
};

export default actions;
