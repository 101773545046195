import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import listUsersActions from 'src/redux/admin/listUsers/listUsersActions';
import listUsersReducers from 'src/redux/admin/listUsers/listUsersReducers';
import editUsersActions from 'src/redux/admin/editUsers/editUsersActions';
import editUsersReducers from 'src/redux/admin/editUsers/editUsersReducers';
import createUsersActions from 'src/redux/admin/createUsers/createUsersActions';
import createUsersReducers from 'src/redux/admin/createUsers/createUsersReducers';
import deleteUsersActions from 'src/redux/admin/deleteUsers/deleteUsersActions';
import deleteUsersReducers from 'src/redux/admin/deleteUsers/deleteUsersReducers';

const adminCombineReducers = combineReducers({
  listUsers: listUsersReducers,
  editUsers: editUsersReducers,
  createUsers: createUsersReducers,
  deleteUsers: deleteUsersReducers
});

const adminAction = {
  listUsers: listUsersActions,
  editUsers: editUsersActions,
  createUsers: createUsersActions,
  deleteUsers: deleteUsersActions
};

const adminReducer = persistReducer(
  {
    key: 'admin',
    storage,
    whitelist: [],
    blacklist: []
  },
  adminCombineReducers
);
export { adminReducer, adminAction };
