import { authAction as auth } from './auth';
import { dashboardAction as dashboard } from './dashboard';
import { menuAction as menu } from './menu';
import { adminAction as admin } from './admin';
import { usersAction as users } from './users';
import { ebookAction as ebook } from './ebook';
import { videoAction as video } from './video';

const actions = {
  auth,
  dashboard,
  menu,
  admin,
  users,
  ebook,
  video
};

export default actions;
