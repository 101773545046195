import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  LinearProgress
} from '@material-ui/core';

import { actions, CONSTANTS } from 'src/redux';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const VideoListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { listVideo } = useSelector((state) => state.video);

  const handleRemove = (id) => {
    dispatch(actions.video.deleteVideo.remove(id));
  };

  const handleChangePage = (newPage) => {
    const actualPage = newPage + 1;
    setPage(actualPage);
    dispatch(actions.video.listVideo.list(actualPage));
  };

  useEffect(() => {
    dispatch(actions.video.listVideo.list(page));
  }, []);

  return (
    <Page className={classes.root} title="VideoListView">
      <Container maxWidth={false}>
        <Toolbar />
        <Typography color="textPrimary" gutterBottom variant="h3">
          Videos Biblioteca
        </Typography>
        {listVideo.status === CONSTANTS.STATUS.DONE ? (
          <Box mt={3}>
            <Results
              videos={listVideo.videos}
              page={page - 1}
              totalVideos={listVideo.totalVideos}
              onRemove={handleRemove}
              onChangePage={handleChangePage}
            />
          </Box>
        ) : (
          <LinearProgress />
        )}
      </Container>
    </Page>
  );
};

export default VideoListView;
