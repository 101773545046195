import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  makeStyles
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  ebooks,
  onRemove,
  onChangePage,
  page,
  totalEbooks
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Card className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Título</TableCell>
                <TableCell>Tipo de Publicação</TableCell>
                <TableCell>Ano</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ebooks.map((ebook) => (
                <TableRow
                  hover
                  key={ebook._id}
                >
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        {ebook.title}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{ebook.typePublication}</TableCell>
                  <TableCell>{ebook.year}</TableCell>
                  <TableCell>
                    <IconButton aria-label="edit" onClick={() => navigate(`edit/${ebook._id}`)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => onRemove(ebook._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalEbooks}
        page={page}
        onChangePage={(event, newPage) => onChangePage(newPage)}
        rowsPerPage={100}
        rowsPerPageOptions={[100]}

      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  ebooks: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalEbooks: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired
};

export default Results;
