import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import listMenusActions from 'src/redux/menu/listMenus/listMenusActions';
import listMenusReducers from 'src/redux/menu/listMenus/listMenusReducers';
import editMenuActions from 'src/redux/menu/editMenu/editMenuActions';
import editMenuReducers from 'src/redux/menu/editMenu/editMenuReducers';
import createMenuActions from 'src/redux/menu/createMenu/createMenuActions';
import createMenuReducers from 'src/redux/menu/createMenu/createMenuReducers';
import deleteMenuActions from 'src/redux/menu/deleteMenu/deleteMenuActions';
import deleteMenuReducers from 'src/redux/menu/deleteMenu/deleteMenuReducers';

const menuCombineReducers = combineReducers({
  listMenu: listMenusReducers,
  editMenu: editMenuReducers,
  createMenu: createMenuReducers,
  deleteMenu: deleteMenuReducers
});

const menuAction = {
  listMenu: listMenusActions,
  editMenu: editMenuActions,
  createMenu: createMenuActions,
  deleteMenu: deleteMenuActions
};

const menuReducer = persistReducer(
  {
    key: 'menu',
    storage,
    whitelist: [],
    blacklist: []
  },
  menuCombineReducers
);
export { menuReducer, menuAction };
