import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  GET_EBOOK: 'EBOOK::GET_EBOOK',
  UPDATE_EBOOK: 'EBOOK::UPDATE_EBOOK',
  UPDATE_EBOOK_REDIRECT: 'EBOOK::UPDATE_EBOOK_REDIRECT',
};

const get = (id) => async (dispatch) => {
  dispatch({
    type: TYPES.GET_EBOOK,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    const response = await client.get(`ebook/${id}`);
    dispatch({
      type: TYPES.GET_EBOOK,
      status: CONSTANTS.STATUS.DONE,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.GET_EBOOK,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const update = (id, ebook) => async (dispatch) => {
  dispatch({
    type: TYPES.UPDATE_EBOOK,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    await client.put(`ebook/${id}`, ebook);
    dispatch({
      type: TYPES.UPDATE_EBOOK,
      status: CONSTANTS.STATUS.DONE
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.UPDATE_EBOOK,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const redirect = () => async (dispatch) => {
  dispatch({
    type: TYPES.UPDATE_EBOOK_REDIRECT,
    status: CONSTANTS.STATUS.DONE
  });
};

const actions = {
  get,
  update,
  redirect
};

export default actions;
