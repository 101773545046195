import { CONSTANTS } from 'src/redux';
import { TYPES as listTotalsActions } from './listTotalsActions';

const initialState = {
  totalUsers: 0,
  totalEbooks: 0,
  totalVideos: 0,
  totalMenus: 0,
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case listTotalsActions.LIST_TOTALS:
      return {
        ...state,
        type: action.type,
        status: action.status,
        totalUsers: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalUsers
          : initialState.totalUsers),
        totalEbooks: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalEbooks
          : initialState.totalEbooks),
        totalVideos: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalVideos
          : initialState.totalVideos),
        totalMenus: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalMenus
          : initialState.totalMenus)
      };
    default:
      return state;
  }
};

export default reducers;
