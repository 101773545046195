import { TYPES as uploadThumbnailActions } from './uploadThumbnailActions';

const initialState = {
  currentFilename: '',
  downloadUrl: '',
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case uploadThumbnailActions.UPLOAD_THUMBNAIL:
      return {
        ...action.payload,
        type: action.type,
        status: action.status
      };
    case uploadThumbnailActions.UPLOAD_THUMBNAIL_RESET:
      return {
        currentFilename: initialState.currentFilename,
        downloadUrl: initialState.downloadUrl,
        type: action.type,
        status: action.status
      };
    default:
      return state;
  }
};

export default reducers;
