import { TYPES as deleteMenuActions } from './deleteMenuActions';

const initialState = {
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case deleteMenuActions.DELETE_MENU:
      return {
        ...state,
        type: action.type,
        status: action.status
      };
    default:
      return state;
  }
};

export default reducers;
