import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles,
  Card,
  CardContent,
  TextField,
  IconButton,
  Paper
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({
  className,
  onChange,
  onSubmit,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate('create')}
        >
          Adicionar usuário
        </Button>
      </Box>
      <Box mt={3} mb={3}>
        <Card>
          <CardContent>
            <Box>
              <Paper
                component="form"
                onSubmit={(event) => {
                  event.preventDefault();
                  onSubmit();
                }}
              >
                <TextField
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        type="submit"
                        onClick={() => onSubmit()}
                      >
                        <SearchIcon />
                      </IconButton>
                    )
                  }}
                  placeholder="Buscar Usuário"
                  variant="outlined"
                  onChange={(event) => onChange(event.target.value)}
                />
              </Paper>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default Toolbar;
