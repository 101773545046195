import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  LIST_USERS: 'ADMIN::LIST_USERS',
};

const list = (page = 1) => async (dispatch) => {
  dispatch({
    type: TYPES.LIST_USERS,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    const response = await client.get(`admin?page=${page}`);
    dispatch({
      type: TYPES.LIST_USERS,
      status: CONSTANTS.STATUS.DONE,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.LIST_USERS,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const actions = {
  list
};

export default actions;
