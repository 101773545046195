import { TYPES as createVideoActions } from './createVideoActions';

const initialState = {
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case createVideoActions.CREATE_VIDEO:
      return {
        ...state,
        type: action.type,
        status: action.status
      };
    case createVideoActions.CREATE_VIDEO_REDIRECT:
      return {
        ...state,
        type: action.type,
        status: action.status
      };
    default:
      return state;
  }
};

export default reducers;
