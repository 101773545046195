import { CONSTANTS } from 'src/redux';
import { TYPES as editMenuActions } from './editMenuActions';

const initialState = {
  menu: {
    _id: '',
    brand: '',
    titlePT: '',
    titleEN: '',
    typeLink: '',
    url: '',
    target: ''
  },
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case editMenuActions.GET_MENU:
      return {
        ...state,
        type: action.type,
        status: action.status,
        menu:
          CONSTANTS.STATUS.DONE === action.status
            ? action.payload
            : initialState.menu
      };
    case editMenuActions.UPDATE_MENU:
      return {
        ...state,
        type: action.type,
        status: action.status
      };
    case editMenuActions.UPDATE_MENU_REDIRECT:
      return {
        ...state,
        type: action.type,
        status: action.status,
        menu: initialState.menu
      };
    default:
      return state;
  }
};

export default reducers;
