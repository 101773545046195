import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import uploadThumbnailActions from 'src/redux/video/uploadThumbnail/uploadThumbnailActions';
import uploadThumbnailReducers from 'src/redux/video/uploadThumbnail/uploadThumbnailReducers';
import listVideoReducers from 'src/redux/video/listVideo/listVideoReducers';
import listVideoActions from 'src/redux/video/listVideo/listVideoActions';
import createVideoActions from 'src/redux/video/createVideo/createVideoActions';
import createVideoReducers from 'src/redux/video/createVideo/createVideoReducers';
import deleteVideoActions from 'src/redux/video/deleteVideo/deleteVideoActions';
import deleteVideoReducers from 'src/redux/video/deleteVideo/deleteEbookReducers';
import editVideoActions from 'src/redux/video/editVideo/editVideoActions';
import editVideoReducers from 'src/redux/video/editVideo/editVideoReducers';

const videoCombineReducers = combineReducers({
  uploadThumbnail: uploadThumbnailReducers,
  listVideo: listVideoReducers,
  createVideo: createVideoReducers,
  deleteVideo: deleteVideoReducers,
  editVideo: editVideoReducers
});

const videoAction = {
  uploadThumbnail: uploadThumbnailActions,
  listVideo: listVideoActions,
  createVideo: createVideoActions,
  deleteVideo: deleteVideoActions,
  editVideo: editVideoActions
};

const videoReducer = persistReducer(
  {
    key: 'video',
    storage,
    whitelist: [],
    blacklist: []
  },
  videoCombineReducers
);
export { videoReducer, videoAction };
