import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Link,
  TablePagination
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  videos,
  onRemove,
  onChangePage,
  page,
  totalVideos
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Card className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Título</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Ano</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {videos.map((video) => (
                <TableRow
                  hover
                  key={video._id}
                >
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        {video.title}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{video.description}</TableCell>
                  <TableCell>{video.year}</TableCell>
                  <TableCell>
                    <Link href={video.link} color="inherit" target="_blank">
                      {video.link}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="edit" onClick={() => navigate(`edit/${video._id}`)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => onRemove(video._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalVideos}
        page={page}
        onChangePage={(event, newPage) => onChangePage(newPage)}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  videos: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalVideos: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired
};

export default Results;
