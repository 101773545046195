import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  makeStyles
} from '@material-ui/core';

import { actions } from 'src/redux';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState(user.password);
  const [type, setType] = useState(user.type);
  const [startDate, setStartDate] = useState(moment(user.startDate).format('DD/MM/YYYY'));
  const [endDate, setEndDate] = useState(moment(user.endDate).format('DD/MM/YYYY'));

  const handleName = (event) => setName(event.target.value);
  const handleEmail = (event) => setEmail(event.target.value);
  const handlePassword = (event) => setPassword(event.target.value);
  const handleType = (event) => setType(event.target.value);
  const handleStartDate = (event) => setStartDate(event.target.value);
  const handleEndDate = (event) => setEndDate(event.target.value);

  const handleSubmit = () => {
    const updateUser = {
      name,
      email,
      password,
      type,
      startDate: (moment(startDate, 'DD/MM/YYYY').unix() * 1000),
      endDate: (moment(endDate, 'DD/MM/YYYY').unix() * 1000)
    };
    dispatch(actions.users.editUsers.update(user._id, updateUser));
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader title="Editar usuário" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Nome"
                name="name"
                onChange={handleName}
                required
                value={name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                onChange={handleEmail}
                required
                value={email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Senha"
                name="password"
                onChange={handlePassword}
                required
                value={password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="type_user">Tipo de usuário</InputLabel>
                <Select
                  native
                  value={type}
                  onChange={handleType}
                  label="Tipo de usuário"
                  inputProps={{
                    name: 'type_user',
                    id: 'type_user',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value="pesquisador">Pesquisador</option>
                  <option value="aluno">Aluno</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Data de início"
                name="startDate"
                onChange={handleStartDate}
                required
                value={startDate}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Data final"
                name="startDate"
                onChange={handleEndDate}
                required
                value={endDate}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Atualizar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default ProfileDetails;
