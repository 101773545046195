import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  LIST_VIDEO: 'VIDEO::LIST_VIDEO',
};

const list = (page) => async (dispatch) => {
  dispatch({
    type: TYPES.LIST_VIDEO,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    const response = await client.get(`video?page=${page}`);
    dispatch({
      type: TYPES.LIST_VIDEO,
      status: CONSTANTS.STATUS.DONE,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.LIST_VIDEO,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const actions = {
  list
};

export default actions;
