import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  LIST_EBOOK: 'EBOOK::LIST_EBOOK',
};

const list = (page = 1, query = '') => async (dispatch) => {
  dispatch({
    type: TYPES.LIST_EBOOK,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const search = query ? `&q=${query}` : '';
    const client = await httpClient();
    const response = await client.get(`ebook?page=${page}${search}`);
    dispatch({
      type: TYPES.LIST_EBOOK,
      status: CONSTANTS.STATUS.DONE,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.LIST_EBOOK,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const actions = {
  list
};

export default actions;
