import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  CREATE_VIDEO: 'VIDEO::CREATE_VIDEO',
  CREATE_VIDEO_REDIRECT: 'VIDEO::CREATE_VIDEO_REDIRECT',
};

const create = (video) => async (dispatch) => {
  dispatch({
    type: TYPES.CREATE_VIDEO,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    await client.post('video', video);
    dispatch({
      type: TYPES.CREATE_VIDEO,
      status: CONSTANTS.STATUS.DONE
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.CREATE_VIDEO,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const redirect = (navegate) => async (dispatch) => {
  dispatch({
    type: TYPES.CREATE_VIDEO_REDIRECT,
    status: CONSTANTS.STATUS.DONE
  });
  navegate('/app/videos');
};

const actions = {
  create,
  redirect
};

export default actions;
