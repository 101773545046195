import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import listTotalsActions from 'src/redux/dashboard/listTotals/listTotalsActions';
import listTotalsReducers from 'src/redux/dashboard/listTotals/listTotalsReducers';

const dashboardCombineReducers = combineReducers({
  listTotals: listTotalsReducers
});

const dashboardAction = {
  listTotals: listTotalsActions
};

const dashboardReducer = persistReducer(
  {
    key: 'dashboard',
    storage,
    whitelist: [],
    blacklist: []
  },
  dashboardCombineReducers
);

export { dashboardReducer, dashboardAction };
