import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';

import { actions, CONSTANTS } from 'src/redux';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Biblioteca Virtual
      </Link>
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

export default function LoginView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { preferences } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const [openAlert, setOpenAlert] = useState(false);
  const [formEmail, setFormEmail] = useState(preferences.email);
  const [formPassword, setFormPassword] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const setRememberEmail = (event) => dispatch(
    actions.auth.preferences.setRememberEmail(event.target.checked, formEmail)
  );

  const login = (email, password) => dispatch(actions.auth.user.login(email, password));
  const closeAlert = () => {
    setOpenAlert(false);
    dispatch(actions.auth.user.closeAlert());
  };

  useEffect(() => {
    if (
      user.status === CONSTANTS.STATUS.DONE
      || user.status === CONSTANTS.STATUS.FAILED
    ) {
      setShowLoading(false);
    }
    if (user.status === CONSTANTS.STATUS.FAILED) setOpenAlert(true);
  });

  const handleFormEmail = (event) => setFormEmail(event.target.value);

  const handleFormPassword = (event) => setFormPassword(event.target.value);

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoading(true);
    login(formEmail, formPassword);
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Admin CMS Biblioteca Virtual
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Collapse in={openAlert}>
              <Alert
                severity="error"
                onClose={closeAlert}
              >
                Erro ao realizar a autenticação. Verifique o usuário e senha.
              </Alert>
            </Collapse>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={formEmail}
              onChange={handleFormEmail}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleFormPassword}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  value="remember"
                  color="primary"
                  onChange={setRememberEmail}
                  checked={preferences.rememberEmail}
                />
              )}
              label="Lembrar e-mail"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Acessar
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
      <Backdrop className={classes.backdrop} open={showLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
