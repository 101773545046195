import { CONSTANTS } from 'src/redux';

export const TYPES = {
  SET_REMEMBER_EMAIL: 'PREFERENCES::SET_REMEMBER_EMAIL'
};

const setRememberEmail = (rememberEmail = false, email = '') => (dispatch) => {
  dispatch({
    type: TYPES.SET_REMEMBER_EMAIL,
    status: CONSTANTS.STATUS.DONE,
    payload: {
      rememberEmail,
      email
    }
  });
};

const actions = {
  setRememberEmail
};

export default actions;
