import { TYPES as createEbookActions } from './createEbookActions';

const initialState = {
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case createEbookActions.CREATE_EBOOK:
    case createEbookActions.CREATE_EBOOK_REDIRECT:
      return {
        ...state,
        type: action.type,
        status: action.status
      };
    default:
      return state;
  }
};

export default reducers;
