import { actions as globalActions, CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  DELETE_USER: 'USERS::DELETE_USER'
};

const remove = (id) => async (dispatch) => {
  dispatch({
    type: TYPES.DELETE_USER,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    await client.delete(`user/${id}`);
    dispatch({
      type: TYPES.DELETE_USER,
      status: CONSTANTS.STATUS.DONE
    });
    dispatch(globalActions.users.listUsers.list());
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.DELETE_USER,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const actions = {
  remove
};

export default actions;
