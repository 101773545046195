import { actions as globalActions, CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  DELETE_EBOOK: 'EBOOK::DELETE_EBOOK'
};

const remove = (id) => async (dispatch) => {
  dispatch({
    type: TYPES.DELETE_EBOOK,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    await client.delete(`ebook/${id}`);
    dispatch({
      type: TYPES.DELETE_EBOOK,
      status: CONSTANTS.STATUS.DONE
    });
    dispatch(globalActions.ebook.listEbook.list());
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.DELETE_EBOOK,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const actions = {
  remove
};

export default actions;
