import { CONSTANTS } from 'src/redux';
import { TYPES as editEbookActions } from './editEbookActions';

const initialState = {
  ebook: {
    _id: '',
    title: '',
    authors: '',
    pages: '',
    isbn_issn: '',
    decription: '',
    keyWord: '',
    publishing: '',
    subject: '',
    bibliographicReferences: '',
    year: '',
    month: '',
    link: '',
    typePublication: '',
    highlight: '',
    restrictedBrand: '',
    brand: '',
    exclusive: '',
    avaible: '',
    publicationAuthorizationTCC: '',
    publicationAuthorization: '',
    supervisor: ''
  },
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case editEbookActions.GET_EBOOK:
      return {
        ...state,
        type: action.type,
        status: action.status,
        ebook: (CONSTANTS.STATUS.DONE === action.status ? action.payload : initialState.ebook)
      };
    case editEbookActions.UPDATE_EBOOK:
      return {
        ...state,
        type: action.type,
        status: action.status
      };
    case editEbookActions.UPDATE_EBOOK_REDIRECT:
      return {
        ...state,
        type: action.type,
        status: action.status,
        ebook: initialState.ebook
      };
    default:
      return state;
  }
};

export default reducers;
