import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  LinearProgress
} from '@material-ui/core';

import { actions, CONSTANTS } from 'src/redux';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const UsersListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { listUsers } = useSelector((state) => state.admin);

  const handleRemove = (id) => {
    dispatch(actions.admin.deleteUsers.remove(id));
  };

  const handleChangePage = (newPage) => {
    const actualPage = newPage + 1;
    setPage(actualPage);
    dispatch(actions.admin.listUsers.list(actualPage));
  };

  useEffect(() => {
    dispatch(actions.admin.listUsers.list());
  }, []);

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Toolbar />
        <Typography color="textPrimary" gutterBottom variant="h3">
          Usários Administrativos
        </Typography>
        {listUsers.status === CONSTANTS.STATUS.DONE ? (
          <Box mt={3}>
            <Results
              users={listUsers.users}
              page={page - 1}
              totalUsers={listUsers.totalUsers}
              onRemove={handleRemove}
              onChangePage={handleChangePage}
            />
          </Box>
        ) : (
          <LinearProgress />
        )}
      </Container>
    </Page>
  );
};

export default UsersListView;
