import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { reducers, actions } from 'src/redux';

const httpClient = async () => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const tokenIsExpired = (token) => {
    const decodedToken = jwtDecode(token);
    return (decodedToken.exp < (new Date().getTime() / 1000) || 0);
  };

  const setBearerToken = (token) => {
    client.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  };

  return new Promise((resolve, reject) => {
    try {
      const { store } = reducers;
      const state = store.getState();
      const { token } = state.auth.user;
      const { logout } = actions.auth.user;

      if (token) {
        if (tokenIsExpired(token)) store.dispatch(logout());
        setBearerToken(token);
      }

      resolve(client);
    } catch (e) {
      reject(new Error('Não foi possível realizar o request!'));
    }
  });
};

export default () => httpClient();
