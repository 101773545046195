import { TYPES as uploadEbookActions } from './uploadEbookActions';

const initialState = {
  currentFilename: '',
  downloadUrl: '',
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case uploadEbookActions.UPLOAD_EBOOK:
      return {
        ...action.payload,
        type: action.type,
        status: action.status
      };
    case uploadEbookActions.UPLOAD_EBOOK_RESET:
      return {
        currentFilename: initialState.currentFilename,
        downloadUrl: initialState.downloadUrl,
        type: action.type,
        status: action.status
      };
    default:
      return state;
  }
};

export default reducers;
