import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  makeStyles
} from '@material-ui/core';

import { actions } from 'src/redux';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, marcas }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [brand, setBrand] = useState('');
  const [titlePT, setTitlePT] = useState('');
  const [titleEN, setTitleEN] = useState('');
  const [typeLink, setTypeLink] = useState('');
  const [url, setUrl] = useState('');
  const [target, setTarget] = useState('');
  const [exclusiveName, setExclusiveName] = useState('');

  const handleBrand = (event) => setBrand(event.target.value);
  const handleTitlePT = (event) => setTitlePT(event.target.value);
  const handleTitleEN = (event) => setTitleEN(event.target.value);
  const handleTypeLink = (event) => setTypeLink(event.target.value);
  const handleUrl = (event) => setUrl(event.target.value);
  const handleTarget = (event) => setTarget(event.target.value);
  const handleExclusiveName = (event) => setExclusiveName(event.target.value);

  const handleSubmit = () => {
    const createMenu = {
      brand,
      titlePT,
      titleEN,
      typeLink,
      url,
      target,
      exclusiveName
    };
    dispatch(actions.menu.createMenu.create(createMenu));
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader title="Criar itens de menu" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Nome exclusivo"
                name="exclusiveName"
                type="text"
                onChange={handleExclusiveName}
                required
                value={exclusiveName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Titulo"
                name="titlePT"
                type="text"
                onChange={handleTitlePT}
                required
                value={titlePT}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Titulo (Inglês)"
                name="titleEN"
                type="text"
                onChange={handleTitleEN}
                required
                value={titleEN}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="brand">Marca:</InputLabel>
                <Select
                  native
                  value={brand}
                  onChange={handleBrand}
                  label="Marca:"
                  inputProps={{
                    name: 'brand',
                    id: 'brand',
                  }}
                >
                  <option aria-label="None" value="" />
                  {marcas.map((marca) => <option value={marca}>{marca}</option>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="typeLink">Tipo de Link:</InputLabel>
                <Select
                  native
                  value={typeLink}
                  onChange={handleTypeLink}
                  label="Tipo de Link:"
                  inputProps={{
                    name: 'typeLink',
                    id: 'typeLink',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value="internal">Interno</option>
                  <option value="external">Externo</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="URL"
                name="url"
                type="text"
                onChange={handleUrl}
                required
                value={url}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="typeTarget">Abrir em:</InputLabel>
                <Select
                  native
                  value={target}
                  onChange={handleTarget}
                  label="Abrir em:"
                  inputProps={{
                    name: 'typeTarget',
                    id: 'typeTarget',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value="sametab">Mesma Aba</option>
                  <option value="newtab">Nova Aba</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Criar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
  marcas: PropTypes.array
};

export default ProfileDetails;
