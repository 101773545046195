import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  GET_MENU: 'MENU::GET_MENU',
  UPDATE_MENU: 'MENU::UPDATE_MENU',
  UPDATE_MENU_REDIRECT: 'MENU::UPDATE_MENU_REDIRECT',
};

const get = (id) => async (dispatch) => {
  dispatch({
    type: TYPES.GET_MENU,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    const response = await client.get(`menu/${id}`);
    dispatch({
      type: TYPES.GET_MENU,
      status: CONSTANTS.STATUS.DONE,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.GET_MENU,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const update = (id, menu) => async (dispatch) => {
  dispatch({
    type: TYPES.UPDATE_MENU,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    await client.put(`menu/${id}`, menu);
    dispatch({
      type: TYPES.UPDATE_MENU,
      status: CONSTANTS.STATUS.DONE
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.UPDATE_MENU,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const redirect = () => async (dispatch) => {
  dispatch({
    type: TYPES.UPDATE_MENU_REDIRECT,
    status: CONSTANTS.STATUS.DONE
  });
};

const actions = {
  get,
  update,
  redirect
};

export default actions;
