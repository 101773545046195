import { actions as globalActions, CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  DELETE_VIDEO: 'VIDEO::DELETE_EBOOK'
};

const remove = (id) => async (dispatch) => {
  dispatch({
    type: TYPES.DELETE_VIDEO,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    await client.delete(`video/${id}`);
    dispatch({
      type: TYPES.DELETE_VIDEO,
      status: CONSTANTS.STATUS.DONE
    });
    dispatch(globalActions.video.listVideo.list());
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.DELETE_VIDEO,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const actions = {
  remove
};

export default actions;
