import { CONSTANTS } from 'src/redux';
import { TYPES as listMenuActions } from './listMenusActions';

const initialState = {
  menus: [],
  totalMenus: 0,
  totalPages: 0,
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case listMenuActions.LIST_MENUS:
      return {
        ...state,
        type: action.type,
        status: action.status,
        menus: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.menus
          : initialState.menus),
        totalMenus: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalMenus
          : initialState.totalMenus),
        totalPages: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalPages
          : initialState.totalPages),
      };
    default:
      return state;
  }
};

export default reducers;
