import { CONSTANTS } from 'src/redux';
import { TYPES as listUsersActions } from './listUsersActions';

const initialState = {
  users: [],
  totalUsers: 0,
  totalPages: 0,
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case listUsersActions.LIST_USERS:
      return {
        ...state,
        type: action.type,
        status: action.status,
        users: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.users
          : initialState.users),
        totalUsers: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalUsers
          : initialState.totalUsers),
        totalPages: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalPages
          : initialState.totalPages),
      };
    default:
      return state;
  }
};

export default reducers;
