import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  LIST_TOTALS: 'DASHBOARD::LIST_TOTALS',
};

const getTotalUsers = async () => {
  try {
    const client = await httpClient();
    const response = await client.get('user?page=1&q=');
    return response.data.totalUsers;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

const getTotalEbooks = async () => {
  try {
    const client = await httpClient();
    const response = await client.get('ebook?page=1&q=');
    return response.data.totalEbooks;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

const getTotalVideos = async () => {
  try {
    const client = await httpClient();
    const response = await client.get('video?page=1');
    return response.data.totalVideos;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

const getTotalMenus = async () => {
  try {
    const client = await httpClient();
    const response = await client.get('menu?page=1');
    return response.data.totalMenus;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

const list = () => async (dispatch) => {
  dispatch({
    type: TYPES.LIST_TOTALS,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const totalUsers = await getTotalUsers();
    const totalEbooks = await getTotalEbooks();
    const totalVideos = await getTotalVideos();
    const totalMenus = await getTotalMenus();
    dispatch({
      type: TYPES.LIST_TOTALS,
      status: CONSTANTS.STATUS.DONE,
      payload: {
        totalUsers,
        totalEbooks,
        totalVideos,
        totalMenus
      }
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.LIST_TOTALS,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const actions = {
  list
};

export default actions;
