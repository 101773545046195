import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  CREATE_EBOOK: 'EBOOK::CREATE_EBOOK',
  CREATE_EBOOK_REDIRECT: 'EBOOK::CREATE_EBOOK_REDIRECT',
};

const create = (ebook) => async (dispatch) => {
  dispatch({
    type: TYPES.CREATE_EBOOK,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    await client.post('ebook', ebook);
    dispatch({
      type: TYPES.CREATE_EBOOK,
      status: CONSTANTS.STATUS.DONE
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.CREATE_EBOOK,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const redirect = () => async (dispatch) => {
  dispatch({
    type: TYPES.CREATE_EBOOK_REDIRECT,
    status: CONSTANTS.STATUS.DONE
  });
};

const actions = {
  create,
  redirect
};

export default actions;
