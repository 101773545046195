import React, { useState, useEffect } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Checkbox,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  LinearProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ClearIcon from '@material-ui/icons/Clear';

import { actions, CONSTANTS } from 'src/redux';

const useStyles = makeStyles(() => ({
  root: {}
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const brands = [
  { id: 'anhanguera', name: 'Anhanguera' },
  { id: 'unime', name: 'Unime' },
  { id: 'uniasselvi', name: 'Uniasselvi' },
  { id: 'unirondon', name: 'Unirondon' },
  { id: 'unopar', name: 'Unopar' },
  { id: 'fama', name: 'Fama' },
  { id: 'unic', name: 'Unic' },
  { id: 'uniderp', name: 'Uniderp' },
  { id: 'pitagoras', name: 'Pitágoras' },
  { id: 'lfg', name: 'LFG' },
  { id: 'uk', name: 'UK' }
];

const VideoDetails = ({ className, video }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uploadThumbnail } = useSelector((state) => state.video);
  const [thumbnail, setThumbnail] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState({
    thumbnail: video.files.find((file) => file.type === 'thumbnail')
  });

  const [inputs, setInputs] = useState({
    title: video.title,
    description: video.description,
    keyWord: video.keyWord,
    year: video.year,
    duration: video.duration,
    productors: video.productors,
    subject: video.subject,
    image: video.image,
    link: video.link,
    videoType: video.videoType,
    brands: video.brands,
    emphasis: video.emphasis,
    restrict: video.restrict,
    exclusive: video.exclusive,
    available: video.available,
    files: !video.files ? [] : video.files
  });

  const handleInputChange = (event) => {
    setInputs((prevInputs) => ({ ...prevInputs, [event.target.name]: event.target.value }));
  };

  const handleSubmit = () => {
    let files = [];
    if (uploadedFiles.thumbnail) files = [...files, uploadedFiles.thumbnail];
    const videoData = {
      ...inputs,
      files
    };
    dispatch(actions.video.editVideo.update(video._id, videoData));
  };

  const handleBrand = (event, updatedBrands) => {
    setInputs((prevInputs) => ({ ...prevInputs, brands: updatedBrands }));
  };

  const handleSwitch = (event) => {
    setInputs((prevInputs) => ({ ...prevInputs, [event.target.name]: event.target.checked }));
  };

  useEffect(() => {
    if (
      uploadThumbnail.type === 'VIDEO::UPLOAD_THUMBNAIL'
      && uploadThumbnail.status === CONSTANTS.STATUS.DONE
    ) {
      setUploadedFiles({
        ...uploadedFiles,
        thumbnail: {
          name: uploadThumbnail.currentFilename,
          type: 'thumbnail'
        }
      });
      setInputs((prevInputs) => ({ ...prevInputs, image: uploadThumbnail.downloadUrl }));
    }
  }, [uploadThumbnail]);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader title="Editar video" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Código de cadastro"
                name="id"
                required
                value={video._id}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Título"
                name="title"
                onChange={handleInputChange}
                required
                value={inputs.title}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Descrição"
                name="description"
                onChange={handleInputChange}
                value={inputs.description}
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Palavras-chave"
                name="keyWord"
                onChange={handleInputChange}
                value={inputs.keyWord}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Ano"
                name="year"
                onChange={handleInputChange}
                value={inputs.year}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Duração"
                name="duration"
                onChange={handleInputChange}
                value={inputs.duration}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Produtores"
                name="productors"
                onChange={handleInputChange}
                value={inputs.productors}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Assunto"
                name="subject"
                onChange={handleInputChange}
                value={inputs.subject}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Link da imagem"
                name="image"
                onChange={handleInputChange}
                required
                value={inputs.image}
                variant="outlined"
                disabled={!!uploadedFiles.thumbnail}
              />
              {uploadThumbnail.status !== CONSTANTS.STATUS.FETCHING
                ? (
                  <>
                    {!uploadedFiles.thumbnail && (
                      <>
                        <div style={{ margin: '6px 0' }}>
                          <DropzoneArea
                            dropzoneText="Clique, ou arraste e solte o arquivo para selecionar"
                            filesLimit={1}
                            showPreviews={false}
                            showPreviewsInDropzone
                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                            onChange={(files) => setThumbnail(files[0])}
                          />
                        </div>
                        <span style={{ marginRight: '6px' }}>
                          <Button
                            color="primary"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            onClick={() => {
                              if (!thumbnail) return;
                              dispatch(actions.video.uploadThumbnail.upload(thumbnail));
                            }}
                            disabled={!thumbnail}
                          >
                            Enviar
                          </Button>
                        </span>
                      </>
                    )}
                    {uploadedFiles.thumbnail && (
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<ClearIcon />}
                        onClick={() => {
                          setUploadedFiles({ ...uploadedFiles, thumbnail: null });
                          setInputs((prevInputs) => ({ ...prevInputs, image: '' }));
                        }}
                        disabled={!uploadedFiles.thumbnail}
                      >
                        Alterar link
                      </Button>
                    )}
                  </>
                ) : <LinearProgress /> }
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Link do vídeo"
                name="link"
                onChange={handleInputChange}
                value={inputs.link}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="videoType">Tipo de vídeo:</InputLabel>
                <Select
                  native
                  value={inputs.videoType}
                  onChange={handleInputChange}
                  label="Tipo de vídeo:"
                  inputProps={{
                    name: 'videoType',
                    id: 'videoType',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value="normalizacao">Normalização</option>
                  <option value="tutorial">Tutorial</option>
                  <option value="videoteca">Videoteca</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <Autocomplete
                multiple
                id="brand"
                options={brands}
                value={brands.filter((brand) => {
                  return inputs.brands.some((selectedBrand) => selectedBrand.id === brand.id);
                })}
                disableCloseOnSelect
                onChange={handleBrand}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="brand"
                    label="Marcas"
                    placeholder="Marcas"
                  />
                )}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={inputs.emphasis}
                    onChange={handleSwitch}
                    name="emphasis"
                  />
                )}
                label="Destaque"
              />
              <FormControlLabel
                control={(
                  <Switch
                    checked={inputs.restrict}
                    onChange={handleSwitch}
                    name="restrict"
                  />
                )}
                label="Restrição"
              />
              <FormControlLabel
                control={(
                  <Switch
                    checked={inputs.exclusive}
                    onChange={handleSwitch}
                    name="exclusive"
                  />
                )}
                label="Exclusivo"
              />
              <FormControlLabel
                control={(
                  <Switch
                    checked={inputs.available}
                    onChange={handleSwitch}
                    name="available"
                  />
                )}
                label="Disponibilidade"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Atualizar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

VideoDetails.propTypes = {
  className: PropTypes.string,
  video: PropTypes.object.isRequired
};

export default VideoDetails;
