import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  CREATE_USER: 'ADMIN::CREATE_USER',
  CREATE_USER_REDIRECT: 'ADMIN::CREATE_USER_REDIRECT',
};

const create = (user) => async (dispatch) => {
  dispatch({
    type: TYPES.CREATE_USER,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    await client.post('admin', user);
    dispatch({
      type: TYPES.CREATE_USER,
      status: CONSTANTS.STATUS.DONE
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.CREATE_USER,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const redirect = () => async (dispatch) => {
  dispatch({
    type: TYPES.CREATE_USER_REDIRECT,
    status: CONSTANTS.STATUS.DONE
  });
};

const actions = {
  create,
  redirect
};

export default actions;
