import { combineReducers, createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

import { authReducer as auth } from './auth';
import { dashboardReducer as dashboard } from './dashboard';
import { menuReducer as menu } from './menu';
import { adminReducer as admin } from './admin';
import { usersReducer as users } from './users';
import { ebookReducer as ebook } from './ebook';
import { videoReducer as video } from './video';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  blacklist: []
};
const combine = combineReducers({
  auth,
  dashboard,
  menu,
  users,
  admin,
  ebook,
  video
});
const persistedReducer = persistReducer(persistConfig, combine);
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(ReduxThunk))
);
const reducers = {
  store,
  persist: persistStore(store)
};

export default reducers;
