import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  CREATE_MENU: 'MENU::CREATE_MENU',
  CREATE_MENU_REDIRECT: 'MENU::CREATE_MENU_REDIRECT'
};

const create = (menu) => async (dispatch) => {
  dispatch({
    type: TYPES.CREATE_MENU,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    await client.post('menu', menu);
    dispatch({
      type: TYPES.CREATE_MENU,
      status: CONSTANTS.STATUS.DONE
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.CREATE_MENU,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const redirect = () => async (dispatch) => {
  dispatch({
    type: TYPES.CREATE_MENU_REDIRECT,
    status: CONSTANTS.STATUS.DONE
  });
};

const actions = {
  create,
  redirect
};

export default actions;
