import { CONSTANTS } from 'src/redux';
import { TYPES as editVideoActions } from './editVideoActions';

const initialState = {
  video: {
    _id: '',
    title: '',
    description: '',
    year: '',
    duration: '',
    productors: '',
    subject: '',
    image: '',
    link: '',
    videoType: '',
    brands: [],
    emphasis: false,
    restrict: false,
    exclusive: false,
    available: false
  },
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case editVideoActions.GET_VIDEO:
      return {
        ...state,
        type: action.type,
        status: action.status,
        video: (CONSTANTS.STATUS.DONE === action.status ? action.payload : initialState.video)
      };
    case editVideoActions.UPDATE_VIDEO:
      return {
        ...state,
        type: action.type,
        status: action.status
      };
    case editVideoActions.UPDATE_VIDEO_REDIRECT:
      return {
        ...state,
        type: action.type,
        status: action.status,
        video: initialState.video
      };
    default:
      return state;
  }
};

export default reducers;
