import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import userActions from 'src/redux/auth/user/userActions';
import userReducers from 'src/redux/auth/user/userReducers';
import preferencesReducer from 'src/redux/auth/preferences/preferencesReducer';
import preferencesAction from 'src/redux/auth/preferences/preferencesAction';

const authCombineReducers = combineReducers({
  user: userReducers,
  preferences: preferencesReducer
});

const authAction = {
  user: userActions,
  preferences: preferencesAction
};
const authReducer = persistReducer(
  {
    key: 'auth',
    storage,
    whitelist: ['user', 'preferences'],
    blacklist: []
  },
  authCombineReducers
);
export { authReducer, authAction };
