import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
  Snackbar,
  LinearProgress
} from '@material-ui/core';

import { actions, CONSTANTS } from 'src/redux';
import Page from 'src/components/Page';
import { Alert } from '@material-ui/lab';
import VideoDetails from './VideoDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const VideoCreateView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createVideo } = useSelector((state) => state.video);

  const [openStackBar, setOpenStackBar] = useState(false);

  const handleCloseSnackBar = () => {
    setOpenStackBar(false);
  };

  useEffect(() => {
    if (
      createVideo.status === CONSTANTS.STATUS.DONE
      && createVideo.type === 'VIDEO::CREATE_VIDEO'
    ) {
      dispatch(actions.video.createVideo.redirect(navigate));
    }
    if (createVideo.status === CONSTANTS.STATUS.FAILED) {
      setOpenStackBar(true);
    }
  }, [createVideo]);

  return (
    <Page className={classes.root} title="VideoCreateView">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            {createVideo.status === CONSTANTS.STATUS.DONE || createVideo.status === null ? (
              <VideoDetails />
            ) : (
              <LinearProgress />
            )}
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={openStackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          Erro ao inserir vídeo
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default VideoCreateView;
