import { CONSTANTS } from 'src/redux';
import httpClient from 'src/helpers/httpClient';

export const TYPES = {
  GET_VIDEO: 'VIDEO::GET_VIDEO',
  UPDATE_VIDEO: 'VIDEO::UPDATE_VIDEO',
  UPDATE_VIDEO_REDIRECT: 'VIDEO::UPDATE_VIDEO_REDIRECT',
};

const get = (id) => async (dispatch) => {
  dispatch({
    type: TYPES.GET_VIDEO,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    const response = await client.get(`video/${id}`);
    dispatch({
      type: TYPES.GET_VIDEO,
      status: CONSTANTS.STATUS.DONE,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.GET_VIDEO,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const update = (id, video) => async (dispatch) => {
  dispatch({
    type: TYPES.UPDATE_VIDEO,
    status: CONSTANTS.STATUS.FETCHING
  });
  try {
    const client = await httpClient();
    await client.put(`video/${id}`, video);
    dispatch({
      type: TYPES.UPDATE_VIDEO,
      status: CONSTANTS.STATUS.DONE
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.UPDATE_VIDEO,
      status: CONSTANTS.STATUS.FAILED
    });
  }
};

const redirect = () => async (dispatch) => {
  dispatch({
    type: TYPES.UPDATE_VIDEO_REDIRECT,
    status: CONSTANTS.STATUS.DONE
  });
};

const actions = {
  get,
  update,
  redirect
};

export default actions;
