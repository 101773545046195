import { TYPES as createMenuActions } from './createMenuActions';

const initialState = {
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case createMenuActions.CREATE_MENU:
    case createMenuActions.CREATE_MENU_REDIRECT:
      return {
        ...state,
        type: action.type,
        status: action.status
      };
    default:
      return state;
  }
};

export default reducers;
