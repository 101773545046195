import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import listEbookActions from 'src/redux/ebook/listEbook/listEbookActions';
import listEbookReducers from 'src/redux/ebook/listEbook/listEbookReducers';
import editEbookActions from 'src/redux/ebook/editEbook/editEbookActions';
import editEbookReducers from 'src/redux/ebook/editEbook/editEbookReducers';
import createEbookActions from 'src/redux/ebook/createEbook/createEbookActions';
import createEbookReducers from 'src/redux/ebook/createEbook/createEbookReducers';
import deleteEbookActions from 'src/redux/ebook/deleteEbook/deleteEbookActions';
import deleteEbookReducers from 'src/redux/ebook/deleteEbook/deleteEbookReducers';
import uploadThumbnailActions from 'src/redux/ebook/uploadThumbnail/uploadThumbnailActions';
import uploadThumbnailReducers from 'src/redux/ebook/uploadThumbnail/uploadThumbnailReducers';
import uploadEbookActions from 'src/redux/ebook/uploadEbook/uploadEbookActions';
import uploadEbookReducers from 'src/redux/ebook/uploadEbook/uploadEbookReducers';

const ebookCombineReducers = combineReducers({
  listEbook: listEbookReducers,
  editEbook: editEbookReducers,
  createEbook: createEbookReducers,
  deleteEbook: deleteEbookReducers,
  uploadThumbnail: uploadThumbnailReducers,
  uploadEbook: uploadEbookReducers
});

const ebookAction = {
  listEbook: listEbookActions,
  editEbook: editEbookActions,
  createEbook: createEbookActions,
  deleteEbook: deleteEbookActions,
  uploadThumbnail: uploadThumbnailActions,
  uploadEbook: uploadEbookActions
};

const ebookReducer = persistReducer(
  {
    key: 'ebook',
    storage,
    whitelist: [],
    blacklist: []
  },
  ebookCombineReducers
);
export { ebookReducer, ebookAction };
