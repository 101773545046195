import { CONSTANTS } from 'src/redux';
import { TYPES as listVideoActions } from './listVideoActions';

const initialState = {
  videos: [],
  totalVideos: 0,
  totalPages: 0,
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case listVideoActions.LIST_VIDEO:
      return {
        ...state,
        type: action.type,
        status: action.status,
        videos: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.videos
          : initialState.videos),
        totalVideos: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalVideos
          : initialState.totalVideos),
        totalPages: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalPages
          : initialState.totalPages),
      };
    default:
      return state;
  }
};

export default reducers;
