import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
  LinearProgress
} from '@material-ui/core';

import { actions, CONSTANTS } from 'src/redux';
import Page from 'src/components/Page';
import VideoDetails from './VideoDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const VideoEditView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { editVideo } = useSelector((state) => state.video);

  useEffect(() => {
    if (
      editVideo.status === CONSTANTS.STATUS.DONE
      && editVideo.type === 'VIDEO::UPDATE_VIDEO'
    ) {
      dispatch(actions.video.editVideo.redirect());
      navigate('/app/videos');
    }
  }, [editVideo]);

  useEffect(() => {
    if (id !== editVideo.video._id) dispatch(actions.video.editVideo.get(id));
  }, [id]);

  return (
    <Page className={classes.root} title="VideoEditView">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            {editVideo.status === CONSTANTS.STATUS.DONE && editVideo.type !== 'VIDEO::UPDATE_VIDEO_REDIRECT' ? (
              <VideoDetails video={editVideo.video} />
            ) : (
              <LinearProgress />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default VideoEditView;
