import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
  LinearProgress
} from '@material-ui/core';

import { actions, CONSTANTS } from 'src/redux';
import Page from 'src/components/Page';
import EbookDetails from './EbookDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EbookEditView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { editEbook } = useSelector((state) => state.ebook);

  useEffect(() => {
    if (
      editEbook.status === CONSTANTS.STATUS.DONE
      && editEbook.type === 'EBOOK::UPDATE_EBOOK'
    ) {
      dispatch(actions.ebook.editEbook.redirect());
      navigate('/app/ebooks');
    }
  }, [editEbook]);

  useEffect(() => {
    if (id !== editEbook.ebook._id) dispatch(actions.ebook.editEbook.get(id));
  }, [id]);

  return (
    <Page className={classes.root} title="EbookEditView">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            {editEbook.status === CONSTANTS.STATUS.DONE && editEbook.type !== 'EBOOK::UPDATE_EBOOK_REDIRECT' ? (
              <EbookDetails ebook={editEbook.ebook} />
            ) : (
              <LinearProgress />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default EbookEditView;
