import { TYPES as preferencesAction } from './preferencesAction';

const initialState = {
  email: '',
  rememberEmail: false,
  status: null,
  type: null
};
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case preferencesAction.SET_REMEMBER_EMAIL:
      return {
        ...state,
        type: action.type,
        status: action.status,
        email: action.payload.rememberEmail ? action.payload.email : '',
        rememberEmail: action.payload.rememberEmail
      };
    default:
      return state;
  }
};
export default reducers;
