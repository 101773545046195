import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDispatch } from 'react-redux';

import { actions } from 'src/redux';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  title: {
    color: '#fff',
    fontSize: '1.5rem'
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const logout = () => dispatch(actions.auth.user.logout());

  const handleOptionsMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
    navigate('cms/users');
  };
  const handleLogout = () => logout();

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Typography className={classes.title} variant="h1">
            Biblioteca Virtual
          </Typography>
        </RouterLink>
        <Box flexGrow={1} />
        <IconButton color="inherit" onClick={handleOptionsMenu}>
          <MoreVertIcon />
        </IconButton>
        <IconButton color="inherit" onClick={handleLogout}>
          <InputIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="long-menu"
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Usuários administrativos</MenuItem>
        </Menu>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
