import { CONSTANTS } from 'src/redux';
import { TYPES as listEbookActions } from './listEbookActions';

const initialState = {
  ebooks: [],
  totalEbooks: 0,
  totalPages: 0,
  status: null,
  type: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case listEbookActions.LIST_EBOOK:
      return {
        ...state,
        type: action.type,
        status: action.status,
        ebooks: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.ebooks
          : initialState.ebooks),
        totalEbooks: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalEbooks
          : initialState.totalEbooks),
        totalPages: (CONSTANTS.STATUS.DONE === action.status
          ? action.payload.totalPages
          : initialState.totalPages),
      };
    default:
      return state;
  }
};

export default reducers;
