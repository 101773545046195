import actions from './Actions';
import reducers from './Reducers';

const CONSTANTS = {
  STATUS: {
    FETCHING: 'FETCHING',
    DONE: 'DONE',
    FAILED: 'FAILED'
  }
};

export { actions, reducers, CONSTANTS };
